import i18n from "@/i18n/config"
import { PanelConfig } from "@/page/App/components/InspectPanel/interface"
import { VALIDATION_TYPES } from "@/utils/validationFactory"

const baseWidgetName = "steps"
export const STEPS_PANEL_CONFIG: PanelConfig[] = [
  {
    id: `${baseWidgetName}-basic`,
    groupName: i18n.t("editor.inspect.setter_group.basic"),
    children: [
      {
        id: `${baseWidgetName}-label-linkContainer`,
        labelName: "Link to a container",
        attrName: "linkContainer",
        setterType: "SWITCH_SETTER",
        expectedType: VALIDATION_TYPES.BOOLEAN,
      },
      {
        id: `${baseWidgetName}-column-decimalPlaces`,
        labelName: i18n.t("editor.inspect.setter_label.container"),
        attrName: "linkWidgetDisplayName",
        bindAttrName: ["linkContainer"],
        shown: (value) => value,
        setterType: "TABS_CONTAINER_SELECT_SETTER",
        expectedType: VALIDATION_TYPES.STRING,
      },
      {
        id: `${baseWidgetName}-views-list`,
        labelName: i18n.t("editor.inspect.setter_label.data_source"),
        useCustomLayout: true,
        attrName: "viewList",
        setterType: "CONTAINER_VIEW_SETTER",
        bindAttrName: ["linkContainer", "linkWidgetDisplayName"],
        shown: (isLink, id) => isLink && id,
        childrenSetter: [
          {
            id: `${baseWidgetName}-options-value`,
            labelName: i18n.t("editor.inspect.setter_label.value"),
            attrName: "key",
            setterType: "INPUT_SETTER",
          },
          {
            id: `${baseWidgetName}-options-label`,
            labelName: i18n.t("editor.inspect.setter_label.label"),
            attrName: "label",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-caption`,
            labelName: i18n.t("editor.inspect.setter_label.caption"),
            attrName: "caption",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-tooltip`,
            labelName: i18n.t("editor.inspect.setter_label.tooltip"),
            attrName: "tooltip",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-hidden`,
            labelName: i18n.t("editor.inspect.setter_label.hidden"),
            setterType: "DYNAMIC_SWITCH_SETTER",
            attrName: "hidden",
            useCustomLayout: true,
            openDynamic: true,
            expectedType: VALIDATION_TYPES.BOOLEAN,
          },
        ],
      },
      {
        id: `${baseWidgetName}-options-mode`,
        attrName: "optionConfigureMode",
        setterType: "RADIO_GROUP_SETTER",
        bindAttrName: ["linkContainer"],
        shown: (value) => !value,
        options: [
          {
            label: i18n.t("widget.public.select_options.manual"),
            value: "static",
          },
          {
            label: i18n.t("widget.public.select_options.mapped"),
            value: "dynamic",
          },
        ],
      },
      {
        id: `${baseWidgetName}-basic-options`,
        useCustomLayout: true,
        attrName: "manualOptions",
        setterType: "STEPS_LIST_SETTER",
        bindAttrName: ["optionConfigureMode", "linkContainer"],
        shown: (value, link) => !link && (!value || value === "static"),
        childrenSetter: [
          {
            id: `${baseWidgetName}-options-value`,
            labelName: i18n.t("editor.inspect.setter_label.value"),
            attrName: "value",
            setterType: "INPUT_SETTER",
          },
          {
            id: `${baseWidgetName}-options-label`,
            labelName: i18n.t("editor.inspect.setter_label.label"),
            attrName: "label",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-caption`,
            labelName: i18n.t("editor.inspect.setter_label.caption"),
            attrName: "caption",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-tooltip`,
            labelName: i18n.t("editor.inspect.setter_label.tooltip"),
            attrName: "tooltip",
            setterType: "INPUT_SETTER",
            expectedType: VALIDATION_TYPES.STRING,
          },
          {
            id: `${baseWidgetName}-options-hidden`,
            labelName: i18n.t("editor.inspect.setter_label.hidden"),
            setterType: "DYNAMIC_SWITCH_SETTER",
            attrName: "hidden",
            useCustomLayout: true,
            openDynamic: true,
            expectedType: VALIDATION_TYPES.BOOLEAN,
          },
        ],
      },
      {
        id: `${baseWidgetName}-option-data-source`,
        labelName: "Data source",
        attrName: "dataSources",
        setterType: "INPUT_SETTER",
        bindAttrName: ["optionConfigureMode", "linkContainer"],
        expectedType: VALIDATION_TYPES.ARRAY,
        shown: (value, link) => !link && value === "dynamic",
        isSetterSingleRow: true,
      },
      {
        id: `${baseWidgetName}-option-mapped`,
        labelName: i18n.t("editor.inspect.setter_label.mapped_option"),
        labelDesc: i18n.t("editor.inspect.setter_tooltip.map_data_option"),
        useCustomLayout: true,
        attrName: "mappedOption",
        setterType: "OPTION_MAPPED_SETTER",
        bindAttrName: ["optionConfigureMode", "linkContainer"],
        shown: (value, link) => !link && value === "dynamic",
        childrenSetter: [
          {
            id: `${baseWidgetName}-mappedOption-labels`,
            labelName: i18n.t("editor.inspect.setter_label.label"),
            attrName: "labels",
            setterType: "OPTION_MAPPED_INPUT_SETTER",
            placeholder: "{{item.label}}",
            expectedType: VALIDATION_TYPES.ARRAY,
          },
          {
            id: `${baseWidgetName}-mappedOption-values`,
            labelName: i18n.t("editor.inspect.setter_label.value"),
            attrName: "values",
            setterType: "OPTION_MAPPED_INPUT_SETTER",
            placeholder: "{{item.value}}",
            expectedType: VALIDATION_TYPES.ARRAY,
          },
          {
            id: `${baseWidgetName}-mappedOption-captions`,
            labelName: i18n.t("editor.inspect.setter_label.caption"),
            attrName: "captions",
            setterType: "OPTION_MAPPED_INPUT_SETTER",
            placeholder: "{{item.caption}}",
            expectedType: VALIDATION_TYPES.ARRAY,
          },
          {
            id: `${baseWidgetName}-mappedOption-tooltips`,
            labelName: i18n.t("editor.inspect.setter_label.tooltip"),
            attrName: "tooltips",
            setterType: "OPTION_MAPPED_INPUT_SETTER",
            placeholder: "{{item.tooltip}}",
            expectedType: VALIDATION_TYPES.ARRAY,
          },
          {
            id: `${baseWidgetName}-mappedOption-hiddens`,
            labelName: i18n.t("editor.inspect.setter_label.hidden"),
            attrName: "hiddens",
            setterType: "OPTION_MAPPED_INPUT_SETTER",
            placeholder: "{{item.hidden}}",
            expectedType: VALIDATION_TYPES.ARRAY,
          },
        ],
      },
      {
        id: `${baseWidgetName}-option-default-manual-step`,
        labelName: "Default step",
        attrName: "defaultStep",
        setterType: "INPUT_SETTER",
        bindAttrName: ["linkContainer"],
        expectedType: VALIDATION_TYPES.STRING,
        shown: (value) => !value,
        isSetterSingleRow: true,
      },
    ],
  },
  {
    id: `${baseWidgetName}-layout`,
    groupName: i18n.t("editor.inspect.setter_group.layout"),
    children: [
      {
        id: `${baseWidgetName}-layout-direction`,
        labelName: i18n.t("editor.inspect.setter_label.direction"),
        setterType: "RADIO_GROUP_SETTER",
        attrName: "direction",
        options: [
          {
            label: "Horizontal",
            value: "horizontal",
          },
          {
            label: "Vertical",
            value: "vertical",
          },
        ],
      },
      {
        id: `${baseWidgetName}-layout-hidden`,
        labelName: i18n.t("editor.inspect.setter_label.hidden"),
        labelDesc: i18n.t("editor.inspect.setter_tooltip.hidden"),
        setterType: "DYNAMIC_SWITCH_SETTER",
        attrName: "hidden",
        useCustomLayout: true,
        openDynamic: true,
        placeholder: "false",
        expectedType: VALIDATION_TYPES.BOOLEAN,
      },
    ],
  },
]
